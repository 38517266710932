import React from 'react';

import { Container } from './styles';

interface INextVideoTumbnailsPLaylistProps {
  thumbnailUrl: string;
  lessonOrder: number;
  lessonTitle: string;
  lessonDuration: string;
  progress: number;
  current?: boolean;
  onClick?: () => void;
}

const NextVideoTumbnailsPLaylist: React.FC<INextVideoTumbnailsPLaylistProps> = ({ thumbnailUrl, lessonOrder, lessonTitle, lessonDuration, progress, onClick }) => {
  return (
    <Container onClick={onClick}>
      <div>
        <figure>
          <div>assistindo</div>
          <img src={thumbnailUrl} alt={lessonTitle} />
        </figure>
        <main>
          <p>{lessonOrder === 1 ? 'Trailer' : 'Aula ' + (lessonOrder - 1)}</p>
          <h3>{lessonTitle}</h3>
        </main>
      </div>
    </Container>
  );
}

export default NextVideoTumbnailsPLaylist;

import React from 'react';

import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Layout from '../components/Layout';

import Dashboard from '../Pages/Dashboard';
import HallDaFama from '../Pages/HallDaFama';
import MinhasRedacoes from '../Pages/MinhasRedacoes';
import MinhasNotificacoes from '../Pages/MinhasNotificacoes';
import Estatisticas from '../Pages/Estatisticas';
import Videos from '../Pages/Videos';
import Player from '../Pages/Player';
import PlayerLives from '../Pages/PlayerLives';
import PlayerVision from '../Pages/PlayerVision';
import Materiais from '../Pages/Materiais';
import MaterialDownload from '../Pages/MaterialDownload';
import Simulados from '../Pages/Simulados';
import Conquistas from '../Pages/Conquistas';
import MinhasConquistas from '../Pages/MinhasConquistas';
import Temas from '../Pages/Temas';
import EnviarRedacaoFoto from '../Pages/EnviarRedacaoFoto';
import EnviarRedacaoTexto from '../Pages/EnviarRedacaoTexto';
import EnviarRedacaoCelular from '../Pages/EnviarRedacaoCelular';
import VisualizarRedacaoTexto from '../Pages/VisualizarRedacaoTexto';
import VisualizarRedacaoFoto from '../Pages/VisualizarRedacaoFoto';
import Calendario from '../Pages/Calendario';
import Forum from '../Pages/Forum';
import ForumTopics from '../Pages/ForumTopics';
import ForumFeed from '../Pages/ForumFeed';
import Perfil from '../Pages/Perfil';
import Preferencias from '../Pages/Preferencias';
import AlterarSenha from '../Pages/AlterarSenha';
import HistoricoLogin from '../Pages/HistoricoLogin';
import SuporteTecnico from '../Pages/SuporteTecnico';
import SuporteCategorias from '../Pages/SuporteCategorias';
import SuportePost from '../Pages/SuportePost';
import SuportTicket from '../Pages/SuportTicket';
import Afiliados from '../Pages/Afiliados';
import CadastroAfiliados from '../Pages/CadastroAfiliados';
import RedacoesNota1000 from '../Pages/RedacoesNota1000';
import PlayerIntensivao from '../Pages/PlayerIntensivao';

const AppRoutes: React.FC = () => (
  <>
    <ScrollToTop>
      <Switch>
        <Route path="/materiais/download/:id" exact component={MaterialDownload} />
        <Layout>
          <Route path="/" exact component={Dashboard} />
          <Route path="/hall-da-fama" exact component={HallDaFama} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/minhas-redacoes" exact component={MinhasRedacoes} />
          <Route path="/temas" exact component={Temas} />
          <Route path="/enviar-redacao/foto/:theme_id" exact component={EnviarRedacaoFoto} />
          <Route path="/enviar-redacao/texto/:theme_id" exact component={EnviarRedacaoTexto} />
          <Route path="/enviar-redacao/celular/:theme_id" exact component={EnviarRedacaoCelular} />
          <Route path="/visualizar-redacao/texto/:wording_id" exact component={VisualizarRedacaoTexto} />
          <Route path="/visualizar-redacao/foto/:wording_id" exact component={VisualizarRedacaoFoto} />
          <Route path="/estatisticas" exact component={Estatisticas} />
          <Route path="/videos" exact component={Videos} />
          <Route path="/analise-redacoes-nota-1000" exact component={RedacoesNota1000} />
          <Route path="/player/:course_slug/:cluster_slug/:lesson_slug?" exact component={Player} />
          <Route path="/lives" exact component={PlayerLives} />
          <Route path="/intensivao" exact component={PlayerIntensivao} />
          <Route path="/redacao-espacial" exact component={PlayerVision} />
          <Route path="/afiliados" exact component={Afiliados} />
          <Route path="/cadastro" exact component={CadastroAfiliados} />
          <Route path="/materiais" exact component={Materiais} />
          <Route path="/simulados" exact component={Simulados} />
          <Route path="/conquistas" exact component={Conquistas} />
          <Route path="/minhas-conquistas" exact component={MinhasConquistas} />
          <Route path="/notificacoes" exact component={MinhasNotificacoes} />
          <Route path="/calendario" exact component={Calendario} />
          <Route path="/forum" exact component={Forum} />
          <Route path="/forum/:slug/:category/topicos" exact component={ForumTopics} />
          <Route path="/forum/:slug/:category/topicos/:id" exact component={ForumFeed} />
          <Route path="/perfil" exact component={Perfil} />
          <Route path="/preferencias" exact component={Preferencias} />
          <Route path="/alterar-senha" exact component={AlterarSenha} />
          <Route path="/historico-de-acessos" exact component={HistoricoLogin} />
          <Route path="/suporte" exact component={SuporteTecnico} />
          <Route path="/suporte/tickets" exact component={SuportTicket} />
          <Route path="/suporte/artigos/:slug" exact component={SuporteCategorias} />
          <Route path="/suporte/artigos/:slug/:article_slug" exact component={SuportePost} />
        </Layout>
      </Switch>
    </ScrollToTop>
  </>
);

export default AppRoutes;

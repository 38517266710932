import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactStars from 'react-stars';
import 'react-toastify/dist/ReactToastify.css';

import NoSignalPlayer from './NoSignalPlayer';
import Footer from '../../components/Footer';
import NextVideoTumbnailsPLaylist from '../../components/NextVideoTumbnailsPLaylist';

import viewIcon from '../../assets/view.svg';

import { Container, ContentGrid1, YoutubeVideoContainer } from './styles';

interface YouTubeVideo {
  snippet: {
    resourceId: {
      videoId: string;
    };
    title: string;
    thumbnails: {
      medium: {
        url: string;
      };
    };
  };
  contentDetails?: {
    duration: string;
  };
}

const PlayerIntensivao: React.FC = () => {
  const [videos, setVideos] = useState<YouTubeVideo[]>([]);

  // Estado para o vídeo selecionado
  const [selectedVideo, setSelectedVideo] = useState<YouTubeVideo | null>(null);

  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
  const PLAYLIST_ID = 'PLhFNxzLJLH3vqGddsQ-3J6ZtB4wNvMhPQ';

  const formatDuration = (duration: string) => {
    return duration
      .replace('PT', '')  // Remove "PT" (prefixo do padrão ISO)
      .replace('H', 'h ')  // Formata horas
      .replace('M', 'm ')  // Formata minutos
      .replace('S', 's');  // Formata segundos
  };

  useEffect(() => {
    // Função para buscar a playlist do YouTube
    const fetchPlaylist = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems`, {
          params: {
            part: 'snippet',
            maxResults: 99,
            playlistId: PLAYLIST_ID,
            key: API_KEY
          }
        });

        const clearVideos = response.data.items.filter((video: any) => video.snippet.title !== 'Private video');

        // Ordena os vídeos pela data de publicação (em ordem crescente)
        const sortedVideos = clearVideos.sort((a: any, b: any) => {
          return new Date(a.snippet.publishedAt).getTime() - new Date(b.snippet.publishedAt).getTime();
        });

        setVideos(sortedVideos); // Armazena os vídeos ordenados
        setSelectedVideo(sortedVideos[0]); // Seleciona o primeiro vídeo por padrão
      } catch (error) {
        console.error('Erro ao carregar a playlist: ', error);
      }
    };

    fetchPlaylist();
  }, [API_KEY]);


  const handleVideoSelect = (video: YouTubeVideo) => {
    setSelectedVideo(video);
  };

  return (
    <Container>
      <h6>Intensivão ENEM</h6>
      {/* Atualiza o título com o vídeo selecionado */}
      <h1>{selectedVideo ? selectedVideo.snippet.title : 'Carregando...'}</h1>
      <ContentGrid1>
        <aside>
          <main>
            {selectedVideo ? (
              <YoutubeVideoContainer>
                {/* Atualiza o iframe com o vídeo selecionado */}
                <iframe
                  src={`https://www.youtube.com/embed/${selectedVideo.snippet.resourceId.videoId}?autoplay=1`}
                  frameBorder="0"
                  title={selectedVideo.snippet.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </YoutubeVideoContainer>
            ) : (
              <YoutubeVideoContainer>
                <NoSignalPlayer />
              </YoutubeVideoContainer>
            )}
          </main>
          <footer>
            <aside>
              <div>
                <img src={viewIcon} alt="Visualizações" />
                <span>{selectedVideo?.snippet.title}</span>
              </div>
            </aside>
            <aside>
              <main />
              <ReactStars
                key={1}
                count={5}
                size={20}
                color1="#adafca"
                color2="#b9993a"
                half={false}
                value={5} //puxar do banco aqui
                onChange={(new_rating: number) => {
                  //
                }}
              />
            </aside>
          </footer>
        </aside>
        <aside>
          {videos.map((video: YouTubeVideo, index: number) => {
            if (video.snippet.title === 'Private video') {
              delete videos[index];
            }

            return (
              <NextVideoTumbnailsPLaylist
                key={video.snippet.resourceId.videoId}
                lessonOrder={index + 1}
                thumbnailUrl={video.snippet.thumbnails.medium.url}
                lessonTitle={video.snippet.title}
                lessonDuration={'Duração Indisponível'} // Usa a duração, se disponível
                progress={0}
                current={selectedVideo?.snippet.resourceId.videoId === video.snippet.resourceId.videoId} // Indica o vídeo atual
                onClick={() => {
                  handleVideoSelect(video)
                }}
              />
            )}
          )}
        </aside>
      </ContentGrid1>
      <Footer />
    </Container>
  );
};

export default PlayerIntensivao;

import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

import { Container } from './styles';

interface INextVideoTumbnailsLiveProps {
  thumbnailUrl: string;
  lessonOrder: number;
  lessonTitle: string;
  lessonDuration: string;
  progress: number;
  current?: boolean;
  href: string;
  onClick?: () => void;
}

const NextVideoTumbnailsLive: React.FC<INextVideoTumbnailsLiveProps> = ({thumbnailUrl, lessonOrder, lessonTitle, lessonDuration, progress, href}) => {
  return (
    <Container href={href} target="_blank" rel="noreferrer">
      <div>
        <figure>
          <div>assistindo</div>
          <img src={thumbnailUrl} alt={lessonTitle} />
        </figure>
        <main>
          <p>Aula {lessonOrder}</p>
          <h3>{lessonTitle}</h3>
          <ProgressBar completed={progress} height="3px" margin="7px 0 0 0" isLabelVisible={false} baseBgColor="rgba(204, 204, 204, 0.3)" bgColor="#6e61c6" />
        </main>
        <aside>
          <p>{lessonDuration}</p>
        </aside>
      </div>
    </Container>
  );
}

export default NextVideoTumbnailsLive;

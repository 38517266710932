import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .apexcharts-tooltip {
    background: ${props => props.theme.colors.textColor}40;
    color: #fff;
    box-shadow: none;
    font-weight: 600;
    backdrop-filter: blur(11px);
    border: 2px solid #e3e3e317;

    .apexcharts-tooltip-title {
      background: #3e3f5e;
      color: #fff;
    }
  }

  .apexcharts-menu {
    background: ${props => props.theme.colors.bgBox}40;
    color: ${props => props.theme.colors.textColor};
    backdrop-filter: blur(11px);
    border: 2px solid #e3e3e317;
    text-align: center;
    top: 30px;
    margin-bottom: 3px;
    padding: 6px 7px;
    font-weight: 700;
  }

  .apexcharts-theme-light .apexcharts-menu-item:hover {
    background: #ffffff2e;
    border-radius: 4px;
    margin-bottom: 3px;
    padding: 6px 7px;
  }

  /* BUTONS PREV AND NEXT SLIDER */
  button[data-action='stop'],
  button[data-action='start'] {
    display: none;
  }

  #customize-controls {
    list-style: none;
    border: none;

    > li:first-child {
      transform: rotate(180deg);

      position: absolute;
      z-index: 999;
      left: 0;
      top: calc(50% - 6px);
      padding: 0 .7rem;
    }

    > li:last-child {
      position: absolute;
      z-index: 999;
      right: 0;
      top: calc(50% - 6px);
      padding: 0 .7rem;
    }
  }

  #customize-toggle {
    visibility: hidden;
  }

ul {
    position: absolute;
    padding: 0;
    width: 100%;
    left: 0;
    z-index: 99;
    top: 50%;
  }

  li {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    padding: .75rem 1.5rem;
    border-radius: 0px;
    opacity: .5;
    transition: opacity .3s ease-in-out 0s;
  }
`;

/* GRID LAYOUT ABAIXO */

export const StatsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  align-items: start;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  margin: 1rem 0;
  align-items: start;
  grid-template-columns: 1fr;
`;

export const Grid = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  > div {
    /* Large display */
    @media (min-width: 768px) {
      min-height: 320px;
    }
  }
`;

export const SliderLine = styled.div`
  padding: 1rem 2rem 3rem 2rem;
  border-radius: 12px;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  position: relative;

  .tns-horizontal.tns-subpixel {
    white-space: normal;
  }

`;

export const Footer = styled.div`
  color: #9aa4bf80;
  text-align: center;
  padding: 2rem 0;
  font-size: 10px;
`;

export const GridContentMain = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2.1fr 0.9fr;
  margin: 1rem 0;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > a {
    height: 100%;
  }
`;

export const GridContentMain2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  align-items: stretch;
  margin: 1rem 0;
  grid-template-areas: "content";
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridContentMain3 = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }

  > aside:nth-child(1) {
    position: relative;
  }

  > aside:nth-child(2) {
    > header {
      display: flex;
      justify-content: space-between;

      > h4 {
        color: ${props => props.theme.colors.textColor};
      }
    }

    padding: 26px 28px 0 26px;
    border-radius: 12px;
    background-color: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};
  }


  @media (max-width: 768px) {
    //grid-template-columns: 50% 25%;
  }
  @media (max-width: 480px) {
    //grid-template-columns: 100% 100%;
  }
  `;

/* FIM GRID LAYOUT */

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  padding: 26px 28px 0 26px;
  border-radius: 12px;
  margin-bottom: 1rem;

  > h4 {
    color: ${props => props.theme.colors.textColor};
  }
`;

export const Live = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  margin-top: -25px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: 1rem;
    width: 100%;

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff3b;
      padding: 5px 10px;
      backdrop-filter: blur(5px);
      border-radius: 15px;

      > input {
        margin-right: 8px;
      }
    }

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      font-size: 27px;
      background: rgb(106 85 157 / 22%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transition: all .4s;

      &::before {
        position: absolute;
        content: '×';
        top: 7px;
      }
    }
  }

  > img {
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
  }
`;

interface BannerEmBreveProps {
  bg: string;
}

export const BannerEmBreve = styled(Link)<BannerEmBreveProps>`
  width: 100%;
  display: flex;
  aspect-ratio: 1320 / 215;
  //background: url('https://poxalulu-api.s3.sa-east-1.amazonaws.com/geral/vision-entrar.webp') no-repeat center bottom #05070b;
  background: url(${props => props.bg}) no-repeat center bottom #05070b;
  background-size: contain;
  border-radius: 12px;
  margin-top: 1rem;
`;
